<template>
  <!-- 开放实验详情  -->
  <div class="main-contain">
    <div class="head">
      <div class="head-title">开放实验详情</div>
    </div>
    <div class="line"></div>
    <div class="main-title">
      <div class="title-left">
        <el-image class="elimage" :src="testimage" />
      </div>
      <div class="title-right">
        <div class="title-text">肝素的制备虚拟仿真实验</div>
        <div class="intro-contain">
          <div class="item">
            <el-image class="elimage" :src="icon0" />
            <div class="nametitle">负责人:</div>
            <div class="name">夏小雨</div>
          </div>
          <div class="item">
            <el-image class="elimage" :src="icon1" />
            <div class="nametitle">所属学科:</div>
            <div class="name">农学</div>
          </div>
          <div class="item">
            <el-image class="elimage" :src="icon2" />
            <div class="nametitle">所属学院:</div>
            <div class="name">农学院</div>
          </div>
        </div>
        <div class="position-icon">
          <div class="left">
            <div class="item">
              <div class="up">
                <el-image class="elimage" :src="icon3" />
                <div class="uptext uptext3">累计参与人次</div>
              </div>
              <div class="bottom">
                <div class="num2">12</div>
                <div class="text">人</div>
              </div>
            </div>
            <div class="itemline"></div>
            <div class="item">
              <div class="up">
                <el-image class="elimage" :src="icon4" />
                <div class="uptext">实验学分</div>
              </div>
              <div class="bottom">
                <div class="num3">3</div>
                <div class="text">分</div>
              </div>
            </div>
            <div class="itemline"></div>
            <div class="item">
              <div class="up">
                <el-image class="elimage" :src="icon5" />
                <div class="uptext uptext2">实验学时</div>
              </div>
              <div class="bottom">
                <div class="num">10</div>
                <div class="text">时</div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="box" @click="toroomreservation">
              <el-image class="elimage" :src="icon6" />
              <div class="text">实验预约</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-contain">
      <div class="left-bts">
        <div
          :class="activebtid === index ? 'bt btactive' : 'bt'"
          v-for="(item, index) in btlist"
          :key="index"
          @click="clickbt(index, item)"
          v-clickDown="index"
        >
          <template v-if="activebtid === index">
            <el-image class="bt-icon" :src="item.icon2" />
          </template>
          <template v-else>
            <el-image class="bt-icon" :src="item.icon" />
          </template>
          <div>{{ item.name }}</div>
        </div>
      </div>
      <div class="center-line"></div>
      <div class="right-contain">
        <component :is="componentId"></component>
      </div>
    </div>
    <Customdialog
      ref="customdialog"
      :showclose="true"
      title="预约实验"
      class="customdialog"
    >
      <div slot="dialogbody" class="dialogbody">
        <el-table
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          style="width: 100%"
          class="Table"
          :header-cell-style="{
            'font-size': '15px',
            color: '#666666',
            'font-weight': 'bold',
            background: '#F7F7F7',
          }"
          :row-style="{
            'font-size': '15px',
            color: '#222222',
            'font-weight': '400',
          }"
        >
          <el-table-column prop="weeknum" label="周次"> </el-table-column>
          <el-table-column prop="week" label="星期"> </el-table-column>
          <el-table-column prop="count" label="节次"> </el-table-column>
          <el-table-column prop="room" label="实验用房"> </el-table-column>
          <el-table-column prop="address" label="地址"> </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <div class="item-right">
                <div class="bt" @click="handleClick(scope.row)">预约</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <div class="bt" @click="canclebt">取消</div>
        <div class="bt btactive" @click="submitbt">确定</div>
      </div>
    </Customdialog>
  </div>
</template>
<script>
import Experimentintroduction from "./components/experimentintroduction.vue";
import Experimentalreport from "./components/experimentalreport.vue";
import Excellentvideodisplay from "./components/excellentvideodisplay.vue";
import Customdialog from "@/components/customdialog.vue";

export default {
  name: "courselearning",
  components: {
    Experimentintroduction,
    Experimentalreport,
    Excellentvideodisplay,
    Customdialog,
  },
  directives: {
    clickDown: {
      inserted(el,binding,index) {
          if(binding.value===0){
              el.click()
          }
      }
    }
  },
  data() {
    return {
      activebtid: 0,
      componentId: "Experimentintroduction",
      testimage: require("@/assets/test.png"),
      icon0: require("@/assets/coursedetails/图层 57.png"),
      icon1: require("@/assets/coursedetails/图层 57.png"),
      icon2: require("@/assets/coursedetails/new/学科.png"),
      icon3: require("@/assets/coursedetails/人.png"),
      icon4: require("@/assets/coursedetails/学分_线性.png"),
      icon5: require("@/assets/coursedetails/时间.png"),
      icon6: require("@/assets/coursedetails/图层 59.png"),

      icon7: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      icon8: require("@/assets/coursedetails/矢量智能对象 (1).png"),

      btlist: [
        {
          name: "实验介绍",
          icon: require("@/assets/coursedetails/图层 61.png"),
          icon2: require("@/assets/coursedetails/new/图层 61.png"),
          component: "Experimentintroduction",
        },
        {
          name: "实验报告",
          icon: require("@/assets/coursedetails/文档.png"),
          icon2: require("@/assets/coursedetails/new/文档.png"),
          component: "Experimentalreport",
        },
        {
          name: "优秀视频展示",
          icon: require("@/assets/coursedetails/图层 64.png"),
          icon2: require("@/assets/coursedetails/new/图层 64.png"),
          component: "Excellentvideodisplay",
        },
      ],
      tableData: [
        {
          weeknum: "1周",
          week: "星期一",
          count: "第1,2节",
          room: "实验室一",
          address: "A教-808室",
        },
      ],
    };
  },
  methods: {
    clickbt(index, item) {
      this.activebtid = index;
      this.componentId = item.component;
    },
    toroomreservation() {
      this.$refs.customdialog.dialogopenbt();
    },
    canclebt() {
      this.$refs.customdialog.dialogclosebt();
    },
    submitbt() {
      this.$refs.customdialog.dialogclosebt();
    },
    handleClick(row){
      console.log(row);
    },
  },
};
</script>
<style scoped lang="scss">
.main-contain {
  margin-bottom: 20px;
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #288add;
      user-select: none;
      white-space: nowrap;
    }
  }
  .line {
    margin-top: 14px;
    // margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }
  .main-title {
    // border: 1px solid red;
    height: 360px;
    background: #f7f7f7;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .title-left {
      .elimage {
        width: 500px;
        height: 315px;
      }
    }
    .title-right {
      //   border: 1px solid red;
      margin-left: 40px;
      flex-grow: 1;
      flex-shrink: 1;
      height: 315px;
      .title-text {
        font-size: 25px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
      }
      .intro-contain {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        .item {
          margin-right: 20px;
          display: flex;
          justify-content: flex-start;
          align-content: flex-end;
          .elimage {
            // width: 23px;
            // height: 20px;
          }
          .nametitle {
            margin-left: 10px;
            margin-right: 10px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          .name {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
          }
        }
      }
      .position-icon {
        // border: 1px solid red;
        margin-top: 60px;
        height: 160px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .item {
            .up {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .uptext {
                margin-top: 10px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #288add;
              }
              .uptext2 {
                color: #dc6868;
              }
              .uptext3 {
                color: #eaa41e;
              }
            }

            .bottom {
              // border: 1px solid red;
              margin: 0px;
              padding: 0px;
              display: flex;
              justify-content: center;
              align-items: flex-end;
              .num {
                margin-bottom: -10px;
                font-size: 50px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #d95353;
              }
              .num2 {
                margin-bottom: -10px;
                font-size: 50px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #ffa700;
              }
              .num3 {
                margin-bottom: -10px;
                font-size: 50px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #288add;
              }
              .text {
                margin-top: 20px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
              }
            }
          }
          .itemline {
            margin-left: 60px;
            margin-right: 60px;
            height: 117px;
            border-right: 1px solid #e7e7e7;
          }
        }
        .right {
          width: 230px;
          height: 160px;
          background: #cceeff;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          .box {
            width: 197px;
            height: 134px;
            background: #3d84ff;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            user-select: none;
            cursor: pointer;
            .text {
              margin-top: 20px;
              font-size: 25px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .bottom-contain {
    // border: 1px solid red;
    height: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .left-bts {
      //   border: 1px solid red;
      margin-top: 60px;
      width: 260px;

      .bt {
        border-left: 2px solid transparent;
        padding-left: 50px;
        height: 48px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        user-select: none;
        cursor: pointer;
        &:hover {
          border-left: 2px solid #3d84ff;
          background: linear-gradient(90deg, #92dbff 0%, #ffffff 100%);
        }
        .bt-icon{
          margin-right: 10px;
        }
        .elimage {
          margin-right: 10px;
        }
      }
      .btactive {
        border-left: 2px solid #3d84ff;
        background: linear-gradient(90deg, #92dbff 0%, #ffffff 100%);
      }
    }
    .center-line {
      margin-top: 20px;
      margin-left: 20px;
      height: 450px;
      border-right: 1px solid #cccccc;
    }
    .right-contain {
      // border: 1px solid red;
      margin-left: 20px;
      margin-top: 30px;

      height: 400px;
      flex: 1;
    }
  }
  .customdialog {
    .dialogbody {
      padding: 20px;
      .Table {
        // border: 1px solid red;
        .item-left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          // margin-left: 10px;
          .title {
            margin-left: 10px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
          }
        }
        .item-middle {
          .title {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
          }
        }

        .item-right {
          display: flex;
          justify-content: center;
          align-items: center;
          .bt {
            margin-right: 10px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #3d84ff;
            user-select: none;
            cursor: pointer;
          }
        }
      }
    }
    .dialogfooter {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 100px;
      .bt {
        margin-right: 10px;
        width: 64px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #7f7f7f;
        border-radius: 4px;
        user-select: none;
        cursor: pointer;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
      }
      .btactive {
        background: #3d84ff;
        border: 1px solid #3d84ff;
        color: white;
      }
    }
  }
}
</style>
